import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'
import { Translation } from 'react-i18next'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import LoginForm from '../components/LoginForm'
import isBrowser from '../utils/isBrowser'
import { withI18next } from 'gatsby-plugin-i18n'

const LogInPage = ({ data, pageContext }) => {
  const { language } = pageContext
  const { sitePlugin } = data
  const { pluginOptions } = sitePlugin
  const { apikey, apiURL } = pluginOptions
  const [logged, setLogged] = useState(
    isBrowser ? localStorage.getItem('isLoggedIn') : false,
  )

  const handleSuccess = (loginResult) => {
    if (loginResult) {
      isBrowser && localStorage.setItem('isLoggedIn', loginResult)
      setLogged(loginResult)
      navigate(`/${language}/login-successful`)
    }
  }

  return (
    <Translation>
      {(t) => (
        <Layout language={language}>
          <main>
            <LightSection>
              <Container maxWidth="420px">
                <SectionTitle as="h2">
                  {t('login:title', { defaultValue: 'Log in' })}
                </SectionTitle>

                <LoginForm
                  onSuccess={handleSuccess}
                  apikey={apikey}
                  apiURL={apiURL}
                />
              </Container>
            </LightSection>
          </main>
        </Layout>
      )}
    </Translation>
  )
}

LogInPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default withI18next()(LogInPage)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        # ns: { in: ["signup", "text-input"] }
      }
    ) {
      ...LocalLocaleFragment
    }
    sitePlugin(name: { eq: "gatsby-theme-sarvasya" }) {
      pluginOptions {
        apiURL
        apikey
      }
    }
  }
`
